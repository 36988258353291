import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Label",
  "type": "Form"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Label`}</strong>{` is used for the title of a semantically linked fields or anywhere inside a
form that is necessary.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Label`}</strong>{` is not rendered as the HTML `}<em parentName="p">{`label`}</em>{` element, but a `}<em parentName="p">{`div`}</em>{`. The reason is that
the native HTML element bounds with a field, which might not be desired everywhere.
If you wish to change the underlying element to `}<em parentName="p">{`label`}</em>{`, use the `}<em parentName="p">{`component`}</em>{` prop.`}</p>
    </blockquote>
    <p><strong parentName="p">{`Label`}</strong>{` has as a configuration it's exact margin from the element that is supposed to
be explaining.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<Label title="A label">
  Some Content for that label
</Label>
`}</code></pre>
    <h2>{`Helper`}</h2>
    <p>{`The label can contain a helper rendered on the right side of the content. It's useful
for additional feedback towards the user, regarding the field.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "200px" }}>
  <Label title="A label" helper="Some help text">
    <TextInput />
  </Label>
</div>
`}</code></pre>
    <h2>{`Messages`}</h2>
    <p><strong parentName="p">{`Label`}</strong>{` can render an additional message on the bottom side of the content. It's useful
for displaying information or success and error states.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`messageType`}</inlineCode>{` prop accepts :`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`info`}</inlineCode>{` - for rendering an info message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`error`}</inlineCode>{` - for rendering an error message`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`success`}</inlineCode>{` - for rendering a success message`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true",
        "live": "true",
        "center": "true"
      }}>{`<div style={{ width: "250px" }}>
  <Label
    title="Password"
    message="Password must be minimum 15 characters"
    messageType="info">
    <TextInput type="password"/>
  </Label>
  <Separator />
  <Label
    title="E-mail"
    message="This e-mail is not valid"
    messageType="error">
    <TextInput />
  </Label>
  <Separator />
  <Label
    title="Username"
    message="Username is available"
    messageType="success">
    <TextInput />
  </Label>
</div>
`}</code></pre>
    <h2>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Label`}</strong>{` passes unused props to the underlying component ( defaults to `}<em parentName="p">{`div`}</em>{` ).`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default -`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the label`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The title of the label`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`helper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message rendered on the right of the content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`component`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`React.ElementType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The underlying root node`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Message displayed on the bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`messageType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"info" / "error" / "success"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"info"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of message for visual feedback`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      